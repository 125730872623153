import Hero from '../components/templates/Hero/Hero';
import Feature from '../components/templates/Feature/Feature';
import Logos from '../components/templates/Logos/Logos';
// import Blog from '../components/templates/Blog/Blog';
import Footer from '../components/templates/Footer/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <Hero></Hero>
      <Feature></Feature>
      <Logos></Logos>
      {/* <Blog></Blog> */}
      <Footer></Footer>
    </div>
  );
}

export default App;
